/* -----------------------------------------------------------------------------
 *
 * Components: Column Content
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/utilities/common.css';

.component {
  padding: 60px 0 20px;

  @media (--medium) {
    padding: 80px 0 60px;
  }

  :global( a ) {
    font-weight: 500;
  }
}

.component__header {
  margin-bottom: 48px;

  @media (--medium) {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 48px;
  }
}

.component__headerCentered {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
    font-size: var( --h5-font-size );
  }

  @media (--medium) {
    h3 {
      font-size: var( --h4-font-size );
    }
  }

  @media (--large) {
    h3 {
      font-size: var( --h5-font-size );
    }
  }

  @media (--hd) {
    h3 {
      font-size: var( --h4-font-size );
    }
  }
}

.component__header_v_align {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.component__headline {
  flex: 2;
  margin-bottom: 2px;

  @media (--medium) {
    width: 50%;
  }

  sup {
    font-size: 40%;
    vertical-align: super;
  }
}

.component__headlineCentered {
  width: unset;
  text-align: center;
}

.component__headline__dark {
  color: var( --color-electric-blue );
}

.component__subhead {
  margin-bottom: 10px;
}

.component__itemList {
  display: flex;
  flex-wrap: wrap;

  @media (--medium) {

  }

  @media (--large) {
    flex-wrap: nowrap;
  }
}

/* --- 2x2 tablet layout --- */
.component__itemListTablet .component__itemTile {
  @media (--medium) {
    margin: 0;
  }

  @media (--large) {
    margin: 0;
  }
}

.component__itemListTablet .component__itemTile:not( :last-of-type ) {
  margin: 0;

  @media (--large) {
    margin-right: 20px;
  }
}

.component__itemListTablet .component__itemTile:nth-child( odd ) {
  @media (--medium) {
    margin-right: 20px;
  }
}

.component__itemListTablet .component__itemTile:nth-child( n+2 ) {
  @media (--medium) {
    margin-bottom: 20px;
  }

  @media (--large) {
    margin-bottom: 0;
  }
}
/* --------- */

.component__itemListOdd {
  @media (--medium) {
    flex-wrap: nowrap;
  }
}

.component__item:not( :last-child ) {
  @media (--large) {
    padding-right: 40px;
  }
}

.component__item:nth-child( odd ) {
  @media (--medium) {
    padding-right: 40px;
  }
}

.component__item,
.component__tile {
  width: 100%;
  margin-bottom: 40px;

  @media (--medium) {
    width: calc( 50% - 10px );
  }

  @media (--large) {
    width: 100%;
  }
}

.component__itemTile {
  display: none;

  @media (--medium) {
    display: block;
  }
}

.component__itemTile:not( :last-of-type ) {
  margin-right: 20px;

  @media (--hd) {
    margin-right: 40px;
  }
}

.component__slider {
  @media (--medium) {
    display: none;
  }
}

.component__tile,
.component__itemTile {
  box-sizing: border-box;
  padding: 40px;
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.component__tile__dark {
  background-color: var( --color-white );
  color: var( --color-midnight-express );
}

.component__tile__white {
  background-color: var( --color-white );
  color: var( --color-midnight-express );
  border-style: solid;
  border-width: 1px;
  border-color: var( --color-aluminium-gray );
}

.component__tile {
  @media (--medium) {
    display: none;
  }
}

.component__tile__roundedCorners {
  border-radius: 16px;
}

.component__icon {
  color: var( --color-primary );
  font-size: 48px;
}

.component__iconAlt {
  color: var( --color-electric-blue );
}

.component__icon-blue {
  color: var( --color-electric-blue );
}

.component__icon-midnight {
  color: var( --color-midnight-express );
}

.component__icon-red {
  color: var( --color-torch-red );
}

.component__itemHeadline {
  margin: 20px 0 10px;
}

.component__navigationContainer {
  @media (--large-max) {
    width: 100px;
    height: 20px;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.component {
  :global( .swiper-pagination ) {
    @media (--large-max) {
     position: relative;
     display: inline-block;
     bottom: unset;
    }
  }

  :global( .swiper-pagination-bullets ) {
   position: relative;
   display: inline-block;
   bottom: unset !important;
  }

  :global( .swiper-pagination .swiper-pagination-bullet ) {
    border: 1px solid var( --color-midnight-express );
    background: var( --color-white );
    opacity: 1;
    margin: 0 2px !important;
  }

  :global( .swiper-pagination .swiper-pagination-bullet-active ) {
   border: 1px solid var( --color-torch-red );
   background: var( --color-torch-red );
   opacity: 1;
  }

  :global( .swiper-pagination__dark .swiper-pagination-bullet ) {
    border: 1px solid var( --color-torch-red );
    background: var( --color-midnight-express );
    opacity: 1;
    margin: 0 2px !important;
  }

  :global( .swiper-pagination__dark .swiper-pagination-bullet-active ) {
    border: 1px solid var( --color-torch-red );
    background: var( --color-torch-red );
    opacity: 1;
  }
}

.component__swiperBtnPrev,
.component__swiperBtnNext {
  position: absolute;
  z-index: 2;
  top: 25%;
  width: 12px;
  height: 20px;
  font-size: rem( 20px );

  @media (--medium) {
    top: 35%;
  }
}

.component__swiperBtn__dark {
  color: var( --color-primary );
}

.component :global( [aria-disabled=true] ) {
  display: none;
}

.component__swiperBtnPrev {
  @media (--large-max) {
   right: unset;
   left: unset;
   top: unset;
   position: relative;
   display: inline-block;
   margin-top: 0;
   height: 20px;
   width: 18px;
  }
}

.component__swiperBtnNext {
  @media (--large-max) {
   right: unset;
   left: unset;
   top: unset;
   position: relative;
   display: inline-block;
   margin-top: 0;
   height: 20px;
   width: 18px;
  }
}
